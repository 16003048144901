import request from './requestnew';

//登录
const login = (user)=>{
    return request('user/login','post',user)
}
const set = (user)=>{
    return request('user/set','post',user)
}
const add = (user)=>{
    return request('user/add','post',user)
}
const get = (data)=>{
    return request('chat/get','post',data)
}
const download = (data)=>{
    return request('chat/download','post',data)
}
const fuzzySearch = (data)=>{
    return request('chat/fuzzySearch','post',data)
}
const testDownload1 = (data)=>{
    return request(' chat/testDownload1','post',data)
}

export default{
    login,set,add,get,download,fuzzySearch,testDownload1
}