// 1.创建一个新的axios实例
// 2.请求拦截器，如果token进行头部携带
// 3.响应拦截器，1.剥离无效数据，2，处理token失效
// 4.导出一个函数，调用当前的axios实例发请求，返回promis

import axios from 'axios';

// import router from './router/index';
// import store from './store';

// 导出基准地址，原因：其他地方不熟通过axios请求的地方用上基准地址
export const baseURL = 'https://node-test.qiaomukeji.com/lingsheng2304/'


const instance = axios.create({
  // axios 一些常用的配置baseURL timeout
  baseURL,
  timeout: 30000,
  // withCredentials:true,
  // headers:{
  //   'Content-Type':'application/json; charset=utf-8',
  //   'Authorization':''
  // },
  // crossDomain : true

})

// axios.interceptors.request.use(function (config) {undefined
// 在发送请求之前做些什么
// return config;
// }, function (error) {undefined
// // 对请求错误做些什么
// return Promise.reject(error);
// });
instance.interceptors.request.use(config => {
  // 拦截业务逻辑
  // 进行请求配置修改
  // 如果本地又token就在头部携带
  // 1获取用户信息
  // const { profile } = store.state.user
  // // 2.判断是否有token
  // if (profile.token) {
  //   // 3.设置token
  //   config.headers.Authorization = `Bearer ${profile.token}`
  // }
  // console.log(config);
  return config
}, err => {
  return Promise.reject(err)
})

// // 添加响应拦截器
// axios.interceptors.response.use(function (response) {undefined
//   // 对响应数据做点什么
//   return response;
//   }, function (error) {undefined
//   // 对响应错误做点什么
//   return Promise.reject(error);
//   });
//   }
// res=>res.data 取出数据，将来调用接口的时候直接拿去用后台的数据
instance.interceptors.response.use(res => res.data, err => {
  // 401 状态码，进入该函数
  // console.log(res)
  // if (err.response && err.response.status === 401) {
  //   // 1.情空无效用户信息
  //   // 2.跳转到登录页码
  //   // 3.跳转需要传参(当前路由地址)给登录页
  //   store.commit('user/setUser', {})
  //   // console.log(router.currentRouter)
  //   // 当前路由地址
  //   // 组件里头: `/user?a=10` $route.path === /user $route.fullPath === /user?a=10
  //   // js模块里： router.currentRoute.value.fullPath 就是当前路由地址,  router.currentRoute是一个ref响应式数据
  //   const fullPath = encodeURIComponent(router.currentRoute.value.fullPath)
  //   // encodeURIComponent 目的是为了转换url编码，防止解析出现问题
  //   router.push('/login?redirectUrl=' + fullPath)
  // }
  return Promise.reject(err)
})

// 请求工具函数
export default (url, method, submitData) => {
  // 负责发请求,亲求地址，请求方式，提交数据
  return instance({
    url,
    method,
    // 1.如果是get请求 需要params来传递submitData  ?a=b&c=10
    // 2.如果不是get请求 需要使用data来传递sumitData  亲求传参
    // []设置一个动态key，写js表达式，js表达式的执行结果当作key
    // method参数：get,Get,GET  转换成小写再进行判断
    [method.toLowerCase() === 'get' ? 'params' : 'data']: submitData
  })
}
